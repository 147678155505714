import { useContext } from 'react'
import { LocaleContext, TenantTokenContext } from '../../../../../contexts'
import { useParams } from 'react-router-dom'
import { translations } from '../../../../../locales'
import { adminRoute } from '../../../../../routesHelpers'
import { TertiaryLink, Header, LabelValue, BooleanIcon } from '../../../../../components'
import { EditIcon } from '../../../../../icons'
import { useFetch } from '../../../../../hooks/useFetch'
import { buildApiUrl, toPercentage } from '../../../../../utils'

const AdminProductGeneralInformation = ({ updatedAt }) => {
  const { locale } = useContext(LocaleContext)
  const { tenantToken } = useContext(TenantTokenContext)
  const { productId } = useParams()

  const url = buildApiUrl(`/octopus_os/t/${tenantToken}/admin/products/${productId}`, { locale })
  const { data } = useFetch(url, {}, [updatedAt])
  const product = data.data

  return (
    <>
      <Header title={translations[locale].helpers.generalInformation.text}>
        <TertiaryLink href={adminRoute('editProduct', { id: productId })} modal>
          <EditIcon />
        </TertiaryLink>
      </Header>

      <LabelValue label={translations[locale].attributes.product.name} value={product.name} className='mb-4' />
      <LabelValue label={translations[locale].attributes.product.description} value={product.description} className='mb-4' />
      <LabelValue label={translations[locale].attributes.product.unit} value={product.unit} className='mb-4' />
      <LabelValue label={translations[locale].attributes.product.satProductCode} value={product.sat_product_code} className='mb-4' />
      <LabelValue label={translations[locale].attributes.product.satUnitCode} value={product.sat_unit_code} className='mb-4' />
      <LabelValue label={translations[locale].attributes.product.subjectToIva} className='mb-4'>
        <div className='flex items-center gap-4'>
          <BooleanIcon value={product.subject_to_iva} />
          <p>({toPercentage(product.iva_percentage)})</p>
        </div>
      </LabelValue>
      <LabelValue label={translations[locale].attributes.product.subjectToIeps}>
        <div className='flex items-center gap-4'>
          <BooleanIcon value={product.subject_to_ieps} />
          <p>({toPercentage(product.ieps_percentage)})</p>
        </div>
      </LabelValue>
    </>
  )
}

export default AdminProductGeneralInformation
